import Grid from '@mui/material/Grid'
import {
	Container,
	Title,
	Description,
	CtaContainer,
	Link,
	Button,
	HeroStyled,
	Subtitle,
} from '@/components/ui/organisms/emotional-banner/emotional-banner.styled'

const EmotionalBanner = ({
	aboveTheFold,
	hasVideo,
	title,
	titleProps,
	subtitle,
	subtitleProps,
	content,
	descriptionProps,
	cta,
	ctaProps,
	additionalContent,
	backgroundComponent,
	url,
	onClick,
	...props
}) => {
	const fullUrl = url ?? (cta && cta?.length === 1 ? cta[0].url : undefined)
	const fullOnClick = onClick ?? (cta && cta?.length === 1 ? cta[0].onClick : undefined)

	return (
		<HeroStyled
			overlayComponent={
				subtitle || title || content || cta?.length || additionalContent ? (
					<Container>
						{subtitle && (
							<Subtitle variant="subheadline1" tabIndex="0" {...subtitleProps}>
								{subtitle}
							</Subtitle>
						)}
						{title && (
							<Title variant="headlineMedium" tabIndex="0" {...titleProps}>
								{title}
							</Title>
						)}
						{content && (
							<Description variant="subheadline1" tabIndex="0" {...descriptionProps}>
								{content}
							</Description>
						)}
						{cta && cta?.length > 0 && (
							<CtaContainer
								display="inline-flex"
								justifyContent={props.horizontalAlign}
								hasSiblings={!!content || !!title}
								{...ctaProps}
							>
								{cta.map(
									({ label, url, variant = 'outlined', ...rest }, key) =>
										label && (
											<Grid item key={key}>
												<Button tabIndex="0" label={label} href={url} variant={variant} {...rest}>
													{label}
												</Button>
											</Grid>
										)
								)}
							</CtaContainer>
						)}
						{additionalContent}
					</Container>
				) : null
			}
			backgroundComponent={
				<Link
					href={fullUrl}
					onClick={fullOnClick}
					sx={{
						cursor: fullUrl || fullOnClick ? 'pointer' : 'auto',
						height: aboveTheFold ? '100%' : undefined,
						display: hasVideo && aboveTheFold ? 'flex' : undefined,
					}}
					tabIndex="0"
				>
					{backgroundComponent}
				</Link>
			}
			{...props}
		/>
	)
}

export default EmotionalBanner
