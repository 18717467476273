import { VideoArtDirection } from '../../particles/art-direction'
import { HideControls } from '../../particles/video/video-background.styled'

const VideoHeroBackground = ({ ...props }) => {
	return (
		<>
			{props.autoplay ? <HideControls /> : null}
			<VideoArtDirection volume={props.autoplay && 0} thresholdInView={0.01} {...props} />
		</>
	)
}

export default VideoHeroBackground
